<template>
    <div class="container is-fluid">
        <template v-if="!loading">
            <b-breadcrumb size="is-small">
                <b-breadcrumb-item tag='router-link' to="/">{{eshop.name}}</b-breadcrumb-item>
                <b-breadcrumb-item tag='router-link' to="/kategorie">Kategorie</b-breadcrumb-item>
                <b-breadcrumb-item tag='router-link' v-for="path in product.path" :key="path.name" :to="path.slug">
                    {{path.name}}
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{product.name}}</b-breadcrumb-item>
            </b-breadcrumb>
        </template>
        <b-skeleton size="is-small" width="40%" :active="loading"></b-skeleton>
        <div class="columns is-variable is-8">
            <div class="column is-4">
                <template v-if="!loading">
                    <b-carousel :autoplay="false" :with-carousel-list="true" :indicator="true" :arrow="true"
                        :overlay="gallery" @click="switchGallery(true)">
                        <b-carousel-item v-for="(item, i) in product.images" :key="i">

                            <b-image :src="item.image" v-if="!gallery"></b-image>
                            <div class="columns is-centered" v-if="gallery">
                                <div class="column is-narrow"><img :src="item.image" class="gimage" /></div>
                            </div>
                        </b-carousel-item>
                        <span v-if="gallery" @click="switchGallery(false)" class="modal-close is-large" />
                        <template #list="props">
                            <b-carousel-list v-model="props.active" :data="product.images" v-bind="al"
                                @switch="props.switch($event, false)" as-indicator />
                        </template>
                        <template #overlay>
                            <div class="has-text-centered has-text-white">

                            </div>
                        </template>
                    </b-carousel>
                </template>
                <br>
                <b-skeleton height="300px" :active="loading"></b-skeleton>
            </div>
            <div class="column">
                <h1 class="title is-1 caps">
                    <b-skeleton size="is-large" height="54px" width="40%" :active="loading"></b-skeleton><template
                        v-if="!loading">{{product.name}}</template>
                </h1>

                <b-taglist class="pp-tags">
                    <b-tag type="is-primary" v-if="product.inStock == '*'"><b>Skladem</b></b-tag>
                    <b-tag type="is-primary" v-if="product.inStock != 0 && product.inStock != '*'"><b>Skladem</b>
                        {{product.inStock}}</b-tag>
                    <b-tag type="is-light" v-if="product.ean != 0" icon="barcode" icon-size="16">{{product.ean}}</b-tag>
                    <b-tag type="is-warning is-light" v-if="product.freeShipping" icon="truck" icon-size="16">Doprava
                        zdarma</b-tag>
                    <b-tag type="is-danger is-light" v-if="product.gift" icon="gift" size="16">Dárek!</b-tag>
                    <b-tag type="is-danger" v-if="product.sale > 0"><b>-{{product.sale}}% sleva</b></b-tag>

                </b-taglist>

                <div v-html="product.perex" class="content"></div>
                <b-rate v-model="product.rating" @change="rate" :disabled="disabled_score" :spaced="true" :max="5"
                    :show-text="true" :min="1" :texts="texts" size="is-medium"></b-rate>

                    <div class="buttons">
                        
                        <b-button size="is-small" type="is-primary" @click="isCardModalActive = true">Přidat gravírování</b-button>
                        <p style="margin-bottom: 5px;" v-if="to_gravier.selected != ''">Vybrané gravírování: <b>{{to_gravier.text}}</b> ({{ product.gravier_price }} Kč)</p>
                        <div class="gravier_preview"  v-if="to_gravier.selected == 'logo'" :style="{backgroundImage: 'url('+to_gravier.image+')'}">
                            <embed :src="to_gravier.image" width="150px" height="150px" />
                        </div>
                    </div>


                    

                <b-field grouped>

                    <p class="control">
                        <b-button size="is-medium" :type='{ "is-primary": product.sale <= 0, "is-danger": product.sale > 0, "is-light": true }' :data-product="product.ID"
                            :id="'button'+product.ID" @click="addToCart(product.ID,0)" icon-left="cart">
                            <b>{{product.price}}
                                {{product.currency}}</b>
                        </b-button>
                    </p>
                    <p class="control is-hidden">
                        <b-button size="is-medium" type="is-light" icon-right="heart" />
                    </p>
                    <p class="control">
                        <b-field label-position="on-border">
                            <b-numberinput placeholder="1" :min="1" type="is-light" size="is-medium"
                                :max="(product.inStock != '*') ? product.inStock : 99" v-model="cart.amount"
                                style="width:200px"></b-numberinput>
                        </b-field>
                    </p>
                </b-field>
                <div v-if="product.sale > 0">Původní cena: <b>{{ product.price_nosale }} Kč</b></div>
                <br><br>
                <p class="title is-size-3">Potřebujete poradit?</p>
                <p class="subtitle">
                    <span class="is-size-4" style="color:#538023">
                        <b-icon icon="phone" size="is-small"></b-icon>
                    </span><span class="is-size-5 has-text-weight-bold">
                        &nbsp;{{eshop.phone}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span class="is-size-4" style="color:#538023">
                        <b-icon icon="at" size="is-small"></b-icon>
                    </span><span class="is-size-5 has-text-weight-bold"> &nbsp;{{eshop.email}}</span>
                </p>
            </div>
        </div>
        <Footer></Footer>
        <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
            <div class="card">
                <div class="card-image">
                    <div class="preview" v-if="radio == 'text'"><p>{{gravier}}</p></div>
                </div>
                <div class="card-content">
                    <div class="columns">
                        <div class="column">
                            <b-field label="Text">
                                <b-input v-model="gravier"></b-input>
                            </b-field>
                        </div>
                        <div class="column is-narrow">
                            <b-field class=" is-primary" label="Logo (pouze vektor)" :class="{'has-name': !!file}">
                                <b-upload @input="nakecej" v-model="file" class="file-label" accept=".pdf,.ai,.svg">
                                    <span class="file-cta">
                                        <b-icon class="file-icon" icon="upload"></b-icon>
                                        <span class="file-label">Načtěte soubor</span>
                                    </span>
                                    <span class="file-name" v-if="file">
                                        {{ file.name }}
                                    </span>
                                </b-upload>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns is-vcentered">
                        <div class="column is-narrow">
                            <div class="block">
                                <b-radio v-model="radio"
                                    name="name"
                                    native-value="text">
                                    Text
                                </b-radio>
                                <b-radio v-model="radio"
                                    name="name"
                                    native-value="logo">
                                    Logo
                                </b-radio>
                            </div>
                        </div>
                        <div class="column">
                            <div class="buttons">
                                <b-button type="is-primary" @click="load_gravier" expanded>Nahrát {{radio}}</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<style>

.gravier_preview
{
    width: 150px;
    height: 150px;
    margin-left: 20px;
    margin-top: calc(-125px);
    background-position: center;
    background-size: contain;
    
}

.gimage {
    height: calc(100vh - 160px);
    margin-bottom: 60px;
    width: auto;

}
@media screen and (max-width: 768px) {
.gimage {
        height: auto;
        margin-bottom: 0px;

    }
}
</style>
<style scoped>
.preview
{
    height: 300px;
    background-image: url("../assets/preview.png");
    background-size: cover;
    background-position: center;
    display: table;
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Oswald';
    width: 100%;
}
.preview p {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
</style>
<script>
    import axios from "axios";
import Footer from "@/components/Footer.vue";

    import {
        SnackbarProgrammatic as Snackbar
    } from 'buefy';
    export default {
        name: 'veShopProductView',
        components: { Footer },
        title: "Produkt - Bedýnky ze dřeva",
        data() {
            return {
                file: "",
                radio: "text",
                isCardModalActive: false,
                gravier: "",
                to_gravier: {
                    text: "",
                    image: "",
                    selected: ""
                },
                eshop: {
                    name: "Bedýnky ze dřeva",
                    phone: "+420 774 369 497",
                    email: "info@bedynkyzedreva.cz"
                },
                cart: {
                    amount: 1
                },
                loading: true,
                product: {

                },
                texts: ['Špatné', 'Nic moc', 'Dobré', 'Skvělé', 'Úžasné!'],
                gallery: false,
                disabled_score: false,
                al: {
                    hasGrayscale: true,
                    itemsToShow: 2,
                    breakpoints: {
                        768: {
                            hasGrayscale: false,
                            itemsToShow: 4
                        },
                        960: {
                            hasGrayscale: true,
                            itemsToShow: 6
                        }
                    }
                },
                arrow: true,
                arrowHover: true,
                drag: true,
                gray: false,
                opacity: false,
                values: 1,
                perList: 4,
                increment: 1,
                repeat: false,
            }
        },
        watch: {
            file() {
                this.radio = "logo"
            }
        },
        computed: {
            price_computed() {
                if(this.to_gravier.selected != "")
                {
                    return this.product.gravier_price*1;
                }
                return 0;
            }
        },
        methods: {
            load_gravier: function() {
                this.to_gravier.selected = this.radio;
                if(this.radio == "logo") {
                    var formData = new FormData();
                    console.log(this.file);
                    var self = this;
                    formData.append('file', this.file);
                    axios.post('https://bedynkyzedreva.cz/fujin3/api/finder-upload.php', formData, {
                      headers: {'Content-Type': 'multipart/form-data'}
                    }).then( (response) => {
                        console.log(response);
                        self.to_gravier.image = response.data.filename;
                        self.file = null;
                        Snackbar.open({
                                duration: 5000,
                                message: response.data.message,
                                type: 'is-success',
                                position: 'is-bottom-left',
                                queue: false,
                            });
                    }).catch((error) => {
                        console.log(error);
                    })
           
                }
                else
                {
                    this.to_gravier.text = this.gravier;
                }
                this.isCardModalActive = false;
            },
            nakecej: function() {
                console.log(this.file)  
            },
            switchGallery(value) {
                this.gallery = value
                if (value) {
                    document.documentElement.classList.add('is-clipped')
                } else {
                    document.documentElement.classList.remove('is-clipped')
                }
            },
            addToCart(productId, gifts) {
                document.getElementById("button" + productId).classList.add("is-loading");
                this.$parent.addToCart(productId, this.cart.amount, 0, gifts, this.to_gravier, this.price_computed);

                setTimeout(function () {
                    document.getElementById("button" + productId).classList.remove("is-loading");
                }, 800);
            },
            async loadProduct() {
                const hostname = "https://bedynkyzedreva.cz/fujin3/api/rest/rest_call.php";
                const product = (window.location.pathname.split('/').pop());
                var data = new FormData();
                data.append('bin', '/apps/persephone/cgi/products');
                data.append('stdin', 'get_product(' + product + ')');
                axios.post(hostname, data, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then((response) => {
                        if (response.data.data.name == undefined) {
                            this.$router.push('/nenalezeno');
                        } else {
                            this.product = response.data.data;
                            document.title = this.product.name + " - Bedýnky ze dřeva";
                            if(response.data.data.can_rate == false)
                            {
                                this.disabled_score = true;
                            }
                            window.bianoTrack('track', 'product_view', { id: this.product.id });
                            this.$gtag.event("view_item", {
                            'event_category': "engagement",
                            'event_label': "view_item",
                            'value': this.product.name
                        });
                            this.loading = false;
                              window.fbq('track', 'ViewContent', {
                              content_name: this.product.name,
                              content_category: this.product.path[this.product.path.length -1]["name"],
                              content_ids: [this.product.id],
                              content_type: 'product',
                              value: this.product.price,
                              currency: 'CZK'
                            });
                        }

                        this.$Progress.finish();

                    })
                    .catch(function (error) {
                        Snackbar.open({
                            duration: 5000,
                            message: error,
                            type: 'is-danger',
                            position: 'is-bottom-left',
                            actionText: 'Undo',
                            queue: false,
                        });
                    });
            },
            async rate() {
                setTimeout(function () { 
                    const hostname = "https://bedynkyzedreva.cz/fujin3/api/rest/rest_call.php";
                var data = new FormData();
                data.append('bin', '/apps/persephone/cgi/products');
                data.append('stdin', 'add_rating(' + this.product.id + ',' + this.product.rating + ')');
                axios.post(hostname, data, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then((response) => {
                        this.$buefy.snackbar.open({
                            message: response.data.data.message,
                            type: 'is-warning',
                            position: 'is-top',
                            actionText: 'OK',
                            indefinite: false,

                        });
                        this.disabled_score = true;
                        this.$Progress.finish();

                    })
                    .catch(function (error) {
                        Snackbar.open({
                            duration: 5000,
                            message: error,
                            type: 'is-danger',
                            position: 'is-bottom-left',
                            actionText: 'Undo',
                            queue: false,
                        });
                    });
                 }.bind(this), 500)
            }
        },
        mounted() {
            this.loadProduct();
        
        }
    }
</script>