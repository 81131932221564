<template>
<div>
    <div class="messages" v-if="messages.length > 0">
      <div class="message-item" v-for="(message, m) in messages" :key="m">
        {{ message.text }}
      </div>
      </div>
    <div id="app" class="veshop-client" :class="{'has-message' : messages.length > 0}">
      <vue-progress-bar></vue-progress-bar>
    
      <div :class="[this.expanded ? 'expanded' : '', 'sidebar-component', messages.length > 0 ? 'has-message' : '']">
        <Sidebar />
      </div>
      <div :class="[this.expanded ? 'expanded' : '', 'divider-cfomponent']"></div>
      <div :class="[this.expanded ? 'expanded' : '', 'main-component']" id="main">

        <div class="topbar-subcomponent">
          <Topbar :cart="cart" />
        </div>
        <div class="content-subcomponent">
          <transition :name="transitionName" mode="out-in">
            <router-view />
          </transition>
        </div>
      </div>

    <vue-cookie-accept-decline
      :debug="false"
      :disableDecline="false"
      :showPostponeButton="false"
      @clicked-accept="cookieClickedAccept"
      @clicked-decline="cookieClickedDecline"
      @clicked-postpone="cookieClickedPostpone"
      @removed-cookie="cookieRemovedCookie"
      @status="cookieStatus"
      elementId="myPanel1"
      position="bottom-left"
      ref="myPanel1"
      transitionName="slideFromBottom"
      type="floating"
    >
      <!-- Optional -->
      <template #postponeContent>&times;</template>

      <!-- Optional -->
      <template #message>
        Tento web používá soubory cookies, aby se zajistilo, že získáte nejlepší možnou zkušenost při používání našeho webu.
        <a href="https://cookiesandyou.com/" target="_blank">Zjistěte více...</a>
      </template>

      <!-- Optional -->
      <template #declineContent>Ne, díky</template>

      <!-- Optional -->
      <template #acceptContent>Jasný!</template>
    </vue-cookie-accept-decline>
    </div>
</div>
</template>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300;400;700&display=swap');
  html,
  body,
  #app {
    overflow: hidden;
    width: 100vw;
  }

  .content-subcomponent>* {
    overflow-x: hidden;
  }

  html {
    position: fixed;
    width: 100%;
    background-color: rgba(221, 245, 207, 0.027)!important;
  }

  .ve-background
  {
    background-color: whitesmoke;
    width: 3000px;
    height: 500px;
    position:absolute;
    left: 0;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .messages {
    background-color: #536645;
    color: white;
    text-align: center;
    padding: 6px 12px;
  }

  .veshop-sidebar {
    width: 100%;
    height: 100vh;
    
    padding: 25px;
    display: flex;
    text-align: center;
    flex-direction: column;
    position: absolute;
    font-family: "Signika Negative",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    transition: all 0.3s ease;
    left: 0;
  }

  * {
    font-family: "Signika Negative",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    
  }

  .amita {
    font-family: 'Signika Negative',
      cursive !important;
  }

  .veshop-sidebar-spacer {
    height: 100%;
  }

  .veshop-sidebar-logo {
    height: 120px;
    
    padding: 30px;
    text-align: center;
  }

  .veshop-sidebar-logo img {
    height: 120px;

  }

  .veshop-sidebar-contact {
    height: 120px;
    
    padding: 10px;
    text-align: center;
    background: url("./assets/box_back.png");
    background-position: top right;
    background-repeat: no-repeat;
  }

  .veshop-sidebar-menu {
    display: flex;
    flex-direction: column;
  }

  .veshop-sidebar-menu .button {
    margin-bottom: 8px;
    justify-content: flex-start;
    font-weight: light;
    font-family: "Signika Negative",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
  }

  @media screen and (max-width: 768px) {
    .oswald {
      font-variant: normal !important;
      font-weight: 200 !important;
    }

    .veshop-sidebar-menu .button {
      font-variant: normal !important;
      font-weight: 200 !important;
    }
  }

  .title {
    font-family: "Signika Negative",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
  }

  .sidebar-component {
    transition: all 0.3s ease;
    width: 250px;
    position: relative;
    &.has-message {
      .veshop-sidebar {
        height: calc(100vh - 36px)
      }
    }
  }

  .vsc-1 {
    font-size: 1.4rem;
    font-weight: lighter;
    margin-bottom: -5px;
  }

  .vsc-1 img {
    margin-right: 5px;
    margin-top: 2px;
  }

  .vsc-2 {
    font-size: 1.8rem;
    font-weight: bold;
  }

  .button.has-icon-custom img {
    margin-bottom: -5px;
    margin-left: -5px;
    margin-right: 5px;
    margin-top: 4px;
  }

  .button.has-icon-custom {
    font-size: 18px!important;
  }

  .divider-component {
    width: 2px;
    margin-left: 5px;
    background: url("./assets/divider.png");
    margin-right: 5px;
    background-size: contain;
  }

  .veshop-client {
    display: flex;
  }



  .main-component {
    width: calc(100% - 250px - 12px);

  }

  .cart-button {
    min-width: 70px;
    height: 80px;
    border-bottom-right-radius: 15px;
    padding: 15px;
    border-bottom-left-radius: 15px;
    background-color: #dcf4ce;
    cursor: pointer;
  }

  .cart-button:hover {
    background-color: #b3cea4;
  }

  .search-button {
    min-width: 70px;
    height: 80px;
    padding: 15px;
  }

  .veshop-topbar {
    margin-top: 0px;
  }

  .fillcol-shc {
    width: 70px;
    max-width: 70px;
    height: 70px;
    background-image: url("./assets/icons/sbasket32.png");
    background-position: center;
    background-size: 32px;
    background-repeat: no-repeat;
  }

  .fillcol-src {
    width: 70px;
    max-width: 70px;
    height: 70px;
    background-image: url("./assets/icons/icons8-search-32.png");
    background-position: center;
    background-size: 32px;
    background-repeat: no-repeat;
  }

  .infocol-shc {
    min-width: 80px;
    text-align: right;
    padding-right: 0px;
  }

  .infocol-shc .s1 {
    font-size: 12px;
    margin-top: 4px;
  }

  .infocol-shc .s2 {
    font-size: 20px;
    color: black;
    font-weight: bold;
    margin-top: -5px;
  }

  .infocol-src .sr1 {
    background: unset;
    border: none;
    font-size: 16px;
    margin-top: 14px;
    text-align: right;
    outline: none;
  }

  .cart-counts {
    position: absolute;
    top: 40px;
    right: 45px;
  }

  .zoom {
    position: absolute;
    width: 40px;
    opacity: 0;
    animation: zoom 1s linear forwards;
  }

  @keyframes zoom {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      top: 40px;
      right: 40px;
    }
  }

  .button.is-primary.is-loading::after {
    border-color: transparent transparent #fff #4c9400 !important;
  }

  .infocol-src .sr1 :focus-visible {
    border: none;
    border-bottom: #dcf4ce;
  }

  .infocol-src {
    padding-right: 0px;
  }

  .fillcol-src {
    padding-left: 0px;
  }

  .main-page {
    margin-top: 10px;
  }

  .main-page-carousel {

    height: 400px;
  }

  .category-head {
    margin-bottom: -5px !important;
  }

  .slide-left-enter-active,
  .slide-left-leave-active,
  .slide-right-enter-active,
  .slide-right-leave-active {
    transition-duration: 0.5s;
    transition-property: height, opacity, transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    overflow: hidden;
  }

  .slide-left-enter,
  .slide-right-leave-active {
    opacity: 0;
    transform: translate(2em, 0);
  }

  .slide-left-leave-active,
  .slide-right-enter {
    opacity: 0;
    transform: translate(-2em, 0);
  }
.subcategory-box-mobile {
    border: 1px #dbdbdb solid;
    border-radius: 4px;
    width: 100%;
    height: 150px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    -ms-user-select: none;
    user-select: none;
    background-color: white;
    font-family: "Signika Negative",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    padding: 0px;
  }
  .subcategory-box {
    border: none;
    border-radius: 10px;
    width: 200px;
    height: 150px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    -ms-user-select: none;
    user-select: none;
    background-color: #c5dbb73d;
    font-family: "Signika Negative",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    padding: 0px;
  }

  .category-box {
    border: none;
    border-radius: 10px;
    width: 200px;
    height: 150px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    -ms-user-select: none;
    user-select: none;
    background-color: #dcf4ce;
    font-family: "Signika Negative",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    padding: 0px;
    
    font-size: 1.5rem;
    color: #4c9400;
  }

  .category-box img {
    width: 50px;
    margin-bottom: -15px;
  }

  .subcategory-box:hover, .subcategory-box-mobile:hover {
    border: 1px #b5b5b5 solid;
  }

  .subcategory-box:active {
    border: 1px #4a4a4a solid;
  }

  .subcategory-box:focus:not(:active) {
    box-shadow: 0 0 0 0.125em rgb(148 118 0 / 25%);
  }

  .subcategory-box:focus {
    border-color: #4c9400;
    color: #363636;
  }

  .subcategory-box .name {
    position: absolute;
    top: 10px;
    
    color: black;
    width: 100%;
    text-align: center;
    font-size: 24px;
  }

    .subcategory-box-mobile .name {
    position: absolute;
    top: 10px;
    
    color: black;
    width: 100%;
    text-align: center;
    font-size: 24px;
  }
  .subcategory-box-mobile img {
    position: absolute;
    bottom: -25%;
    right: -15%;
    max-height: 130px;
  }
  .subcategory-box img {
    position: absolute;
    bottom: -25%;
    right: -15%;
    mix-blend-mode: color-burn;
    max-height: 130px;
  }

  .subcategory-box .counter {
    position: absolute;
    color: black;
    bottom: 10px;

    left: 15px;
    text-align: left;
    
  }

  .subcategory-box .count {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: -5px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.3s;
    transition-property: height,
      opacity;
    overflow: hidden;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }

  .field:not(:last-child) {
    margin-bottom: 0.25rem;
  }

  .product-box {
    box-shadow: rgba(87, 109, 59, 0.1) 0px 0px 5px 0px, rgba(92, 218, 20, 0.1) 0px 0px 1px 0px;
    border-radius: 10px;
    width: 100%;
    height: 350px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    -ms-user-select: none;
    user-select: none;
    background-color: white;
    transition: all 0.2s ease;
    padding: 0px;
  }

  @media screen and (max-width: 768px) {
    .product-box {
      height: 350px;
    }
  }

  .brick {
    position: relative !important;

  }

  .brick.imh {
    height: 100%;
  }

  .brick.nmh {
    font-family: "Signika Negative",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    font-size: 1.5rem;
  }

  .product-most {
    border: 1px #dbdbdb solid;
    border-radius: 4px;
    width: 100%;
    height: 220px;
    padding: 15px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    -ms-user-select: none;
    user-select: none;
    background-color: white;
    transition: all 0.2s ease;

  }

  .product-most .columns {
    height: calc(100% + 25px);
  }

  .product-most .product-image {
    height: 100%;
    top: 0;
    bottom: 0;
  }

  .cart-item-box {
    border: 1px #dbdbdb solid;
    border-radius: 4px;
    width: 100%;
    position: relative;
    overflow: hidden;
    -ms-user-select: none;
    user-select: none;
    background-color: white;
    transition: all 0.2s ease;
    padding: 15px;
    padding-right: 35px;
  }

  .cart-shippingmeter {
    margin-top: 15px;
  }

  .cart-shippingmeter p {
    text-align: center;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .cart-item-image {
    height: 150px;
  }

  .cart-item-cols {
    height: 100%;
  }

  .cart-item-title {
    font-family: "Signika Negative",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    font-size: 1.5rem;
    color: black;
  }

  .product-box:hover {
    box-shadow: 0 0 0 0.125em rgba(17, 148, 0, 0.25);
    border-color: #4c9400;
  }

  .product-box:hover p {
    text-decoration: underline;
  }

  .pb-tags {
    position: absolute;
    top: 15px;
    left: 15px;
  }

  .pb-counter {
    position: absolute;
    top: 15px;
    right: 20px;
    font-family: "Signika Negative",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    text-align: center;
    background-color: white;
    -webkit-box-shadow: 0px 0px 12px 15px #FFFFFF;
    box-shadow: 0px 0px 12px 15px #FFFFFF;
    border-radius: 50px;
  }

  .product-image {
    position: absolute;
    width: 70%;
    left: 0;
    right: 0;
    top: 30px;
    bottom: 100px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;
  }

  .pb-counter .count {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: -5px;
  }

  .pb-counter .countTag {
    font-size: 10px;
  }

  .pb-name-buttons {
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
  }

  .pb-name-buttons p {
    font-family: "Signika Negative",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    color: black;
    margin-bottom: 10px;
    font-size: 21px;
  }
  .pagination-link {
    box-shadow: rgba(87, 109, 59, 0.267) 0px 0px 5px 0px, rgba(92, 218, 20, 0.1) 0px 0px 1px 0px!important;
    border: none!important;
    border-radius: 10px!important;
  }
  .paginate-cart .step-details,
  .paginate-cart .step-title {
    font-family: "Signika Negative",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 1.5rem !important;
    font-weight: normal !important;
  }

  .fade-in-bottom {
    -webkit-animation: fade-in-bottom 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-bottom 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  /* ----------------------------------------------
   * Generated by Animista on 2022-1-2 2:3:47
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */

  /**
   * ----------------------------------------
   * animation fade-in-bottom
   * ----------------------------------------
   */
  @-webkit-keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  .veshop-client {
    height: 100vh;
    max-width: 1500px;
    margin: auto;
    &.has-message {
      height: calc(100vh - 36px);
    }
  }

  .sidebar-component {
    height: -webkit-fill-available;
  }

  .main-component {
    overflow: scroll;
  }

  .buttons-custom .button:not(:last-child) {
    margin-right: 10px;
  }

  .filters {
    /*background-color: #dcf4ce;*/
    width: 100%;
    height: auto;
  }

  ::selection {
    background: #4c9400;
    color: white;
  }

  ::-moz-selection {
    background: #4c9400;
    color: white;
  }

  .clickable {
    cursor: pointer;
  }

  .clickable:hover {
    text-decoration: underline;
  }

  .caps {
    
  }

  .pp-tags {
    margin-top: -15px
  }

  .topbar-logo-tablet,
  .topbar-button-tablet {
    height: 50px;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px;

  }

  .topbar-button-tablet {
    width: 0;
  }

  .topbar-button-tablet:focus:not(:active) {
    box-shadow: none;
    color: rgb(148 118 0 / 25%);
  }

  @media only screen and (max-width: 1024px) {
    .veshop-sidebar {
      transition: all 0.3s ease;
      left: -600px;
      opacity: 0;
    }

    .veshop-sidebar.expanded {
      transition: all 0.3s ease;
      left: 0;
      opacity: 1;
    }

    .divider-component {
      transition: all 0.3s ease;
      display: none;
    }

    .divider-component.expanded {
      transition: all 0.3s ease;
      width: 2px;
    }

    .sidebar-component {
      width: 0px;
      transition: all 0.3s ease;
      position: relative;
    }

    .sidebar-component.expanded {
      transition: all 0.3s ease;
      width: 250px;
    }

    .main-component {
      transition: all 0.3s ease;
      width: 100%;
    }

    .main-component.expanded {
      left: 250px;
      transition: all 0.3s ease;
      right: -250px;
      height: 100%;
      position: absolute;
    }

    .veshop-sidebar-logo img {
      height: 70px;
      width: 70px;
    }
  }

  @media only screen and (max-width: 768px) {

    .category-box,
    .subcategory-box {
      width: calc(100vw - 64px);
    }

    .subcategory-box .b2 {
      width: calc(100% / 3);
    }
  }

  .blanket {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: -2px;
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 600;
    transition: all 0.5s ease;
  }

  .search-box {
    width: 1000px;
    min-height: 400px;
    background-color: white;
    border: 1px #dbdbdb solid;
    position: fixed;
    z-index: 605;
    right: 120px;
    margin-top: 15px;
    border-radius: 5px;
    padding: 15px;
  }

  .zobak {
    border-left: 1px #dbdbdb solid;
    border-top: 1px #dbdbdb solid;
    width: 40px;
    height: 40px;
    transform: rotate(45deg);
    background-color: white;
    right: 100px;
    margin-top: -35px;
    position: absolute;
  }

  .no-results {
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-image: url(./assets/no-results.svg);
    background-position: center;
  }

  .not-found {
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-image: url(./assets/notfound.svg);
    background-size: 50%;
    background-position: center;
  }

  .tp1 {
    padding: 20px;
    background-color: #dcf4ce;
    cursor: pointer;
  }

  .filters {
    padding: 20px;
    box-shadow: rgba(87, 109, 59, 0.1) 0px 0px 5px 0px, rgba(92, 218, 20, 0.1) 0px 0px 1px 0px;
    border-radius: 10px;
    margin-bottom: 25px;
  }

  .tp1-subtitle {
    font-size: 2rem;
    color: black;
    font-style: italic;
    font-family: "Roboto",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
  }

  .tp1-title {
    font-family: "Signika Negative",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    font-weight: bold;
    font-size: 6rem;
    color: #4c9400;
    line-height: 6rem;
  }

  .tp1-price {
    position: absolute;
    right: 35px;
    color: black;
    font-family: "Signika Negative",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    font-size: 2rem;
    bottom: 80px;
  }

  .tp2-price * {
    padding: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    height: auto;
    font-family: "Signika Negative",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    font-size: 1.5rem;
  }

  .tp2-title {
    font-family: "Signika Negative",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    font-weight: 500;
    font-size: 4rem;
    color: black;
    line-height: 6rem;
  }

  .tp2-subtitle {
    font-size: 1.5rem;
    color: black;
    font-style: italic;
    font-family: "Roboto",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    margin-bottom: 0.5rem;
  }

  .tp2-image {
    height: auto;
    width: auto;
    display: block;
    max-width: 300px;
    margin: auto;
    max-height: 300px;
  }

  .tp2-image-wrap {
    background-image: url("https://bedynkyzedreva.cz/assets/img/boxestp2.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .tp2 {
    background-image: url("https://bedynkyzedreva.cz/assets/img/boxes2tp2.svg");
    background-size: 210px;
    background-position: bottom right;
    background-repeat: no-repeat;
  }

  .tp1 .columns,
  .tp2 .columns,
  .tp1,
  .tp2 {
    height: 400px;
    min-height: 400px;
    max-height: 400px;
  }

  .blg-leader {
    overflow: hidden;
    width: 100%;
    position: relative;
    height: 300px;
    background-color: #dcf4ce;
    padding: 50px;
  }

  .blgl-inner {
    width: 100%;
    height: calc(300px - 100px);
    background-image: url(./assets/dots.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    text-align: center;
    padding: 30px;
  }

  .blgl-inner::after {
    max-width: 300px;
  }

  .blgli-title {
    font-family: "Roboto",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    
    font-size: 2.25rem;
    font-weight: bold;
    margin-bottom: -5px;
  }

  .blgli-meta {
    font-size: 0.9rem;

  }

  .blgli-button {
    margin-top: 10px;
  }

  .blg-image {
    height: 100%;
    width: auto;
    position: absolute;
    opacity: 0.2;
    top: 0;
    overflow: hidden;
    right: 0;
    -webkit-mask-image: -webkit-gradient(linear, top left, bottom left, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    mask-image: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  }
</style>

<script>
  import Sidebar from '@/components/Sidebar.vue';
  import Topbar from '@/components/Topbar.vue';
  import axios from "axios";
  import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
  import {
    SnackbarProgrammatic as Snackbar
  } from 'buefy';
  var base64 = require('base-64');
  var utf8 = require('utf8');
  //import Footer from '@/components/Footer.vue';
  const DEFAULT_TRANSITION = 'fade';
  export default {
    name: 'veShop',
    title: 'Bedýnky ze dřeva',
    components: {
      Sidebar,
      Topbar,
      VueCookieAcceptDecline
      /**Footer*/
    },

    data() {
      return {
        expanded: false,
        blanking: true,
        messages: [],
        transitionName: DEFAULT_TRANSITION,
        cart: {
          hasItems: false,
          items: 0,
          total: 0,
          totalClean: 0
        },

      };
    },
    mounted() {
      this.createCart();
      this.addVisit();
      this.getCart();
    },
    created() {
      this.$gtag.screenview({
        app_name: 'Bedynkyzedreva.cz',
        screen_name: 'Home page',
      });
      this.$gtag.set({
      'currency': 'CZK',
      'country': 'CZ'
    });
      this.$Progress.start();
      this.$router.beforeEach((to, from, next) => {
        if (to.meta.progress !== undefined) {
          let meta = to.meta.progress;
          this.$Progress.parseMeta(meta);
          
        }
        if(to.name != 'Produkt')
        window.bianoTrack('track', 'page_view');
        
        this.$gtag.pageview(to);
        this.$Progress.start();
        this.expanded = false;
        next();
      });

    },
    watch: {
      messages(now, before) {
        if(now != before && !this.$cookies.get("showMessage")) {

        this.$buefy.dialog.confirm({
          message: '<div style="font-size:1.8rem; text-align:center">' + this.messages[0].text + '</div>',
          confirmText: 'Beru na vědomí',
          cancelText: 'Teď to nechci číst',
          onConfirm: () => this.$cookies.set("showMessage")
        })
        }
      }
    },
    methods: {
      cookieClickedAccept() {
        window.bianoTrack('consent', true);
      },
      cookieClickedDecline() {
        window.bianoTrack('consent', false);
      },
      cookieClickedPostpone() {
        window.bianoTrack('consent', false);
      },
      cookieRemovedCookie() {
        window.bianoTrack('consent', false);
      },
      cookieStatus(status) {
        if(status == 'accept')
        {
          window.bianoTrack('consent', true);
        }
        else
        {
          window.bianoTrack('consent', false);
        }
      },
      getCart() {
        const hostname = "https://bedynkyzedreva.cz/fujin3/api/rest/rest_call.php";
        var data = new FormData();
        data.append('bin', '/apps/persephone/cgi/cart');
        data.append('stdin', 'get_cart_topbar(' + this.$cookies.get("persephone_cart") + ')');
        axios.post(hostname, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then((response) => {
            this.products = response.data.data;
            if (response.data.data.status == "OK") {
              this.cart.hasItems = response.data.data.topbar.hasItems;
              this.cart.items = response.data.data.topbar.items;
              this.cart.total = response.data.data.topbar.total;
              this.cart.totalClean = response.data.data.topbar.totalClean;
              this.messages = response.data.data.messages;
              
            } else {
              console.log(response);

              Snackbar.open({
                duration: 5000,
                message: "Při načítání košíku se stala nehoda, prosím aktualizujte stránku. ",
                type: 'is-danger',
                position: 'is-bottom-left',
                actionText: 'Undo',
                queue: false,
              });
            }
          })
          .catch(function (error) {
            Snackbar.open({
              duration: 5000,
              message: error,
              type: 'is-danger',
              position: 'is-bottom-left',
              actionText: 'Undo',
              queue: false,
            });
          });
      },
      getCookie(name) {
        var dc = document.cookie;
        var prefix = name + "=";
        var begin = dc.indexOf("; " + prefix);
        if (begin == -1) {
          begin = dc.indexOf(prefix);
          if (begin != 0) return null;
        } else {
          begin += 2;
          var end = document.cookie.indexOf(";", begin);
          if (end == -1) {
            end = dc.length;
          }
        }
        // because unescape has been deprecated, replaced with decodeURI
        //return unescape(dc.substring(begin + prefix.length, end));
        return decodeURI(dc.substring(begin + prefix.length, end));
      },
      addVisit() {
        var myCookie = this.getCookie("Visited");
        if (myCookie == null) {
          this.setCookie("Visited", true, 365);
          const hostname = "https://bedynkyzedreva.cz/fujin3/api/rest/rest_call.php";
          var data = new FormData();
          data.append('bin', '/apps/persephone/cgi/mainpage');
          data.append('stdin', 'add_visit(1)');
          axios.post(hostname, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          // .then((response) => {});
        }
      },
      setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      },
      addToCart(product, amount, variant, gifts, to_gravier = {}, price="") {
        if (this.$cookies.get("persephone_cart") == undefined || this.$cookies.get("persephone_cart") == "") {
          this.createCart();
        }
        this.$gtag.event("add_to_cart", {
        'event_category': "ecommerce",
          'event_label': "add_to_cart",
            'value': product
      })
      

        var utfgravier = utf8.encode(JSON.stringify(to_gravier));
        const hostname = "https://bedynkyzedreva.cz/fujin3/api/rest/rest_call.php";
        var data = new FormData();
        data.append('bin', '/apps/persephone/cgi/cart');
        data.append('stdin', 'add_to_cart(' + product + ',' + amount + ',' + variant + ',' + this.$cookies.get(
          "persephone_cart") + ',' + gifts + ',' + base64.encode(utfgravier) + ',' + price+ ')');
        axios.post(hostname, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then((response) => {
            this.products = response.data.data;
            if (response.data.data.status == "OK") {
              this.cart.hasItems = response.data.data.topbar.hasItems;
              window.fbq('track', 'AddToCart', response.data.data.pixel);
              window.bianoTrack('track', 'add_to_cart', {
              id: product,
              quantity: amount,
              unit_price: response.data.data.pixel.value,
              currency: 'CZK',
            });
              this.cart.items = response.data.data.topbar.items;
              this.cart.total = response.data.data.topbar.total;
              Snackbar.open({
                duration: 7000,
                message: "Produkt přidán do košíku. Právě máte <b>" + this.cart.items + " produktů</b> v košíku.",
                size: "is-medium",
                pauseOnHover: true,
                type: 'is-primary',
                position: 'is-top',
                actionText: 'Do košíku',
                onAction: () => {
                  this.$router.push('/kosik')
                },
                queue: false
              });
            } else {
              console.log(response);

              Snackbar.open({
                duration: 5000,
                message: "Při tvorbě košíku se stala nehoda, prosím aktualizujte stránku. ",
                type: 'is-danger',
                position: 'is-bottom-left',
                actionText: 'Undo',
                queue: false,
              });
            }
          })
          .catch(function (error) {
            Snackbar.open({
              duration: 5000,
              message: error,
              type: 'is-danger',
              position: 'is-bottom-left',
              actionText: 'Undo',
              queue: false,
            });
          });
      },
      createCart() {
        if (this.$cookies.get("persephone_cart") == undefined || this.$cookies.get("persephone_cart") == "" || this
          .$cookies.get("persephone_cart") == null) {
          const hostname = "https://bedynkyzedreva.cz/fujin3/api/rest/rest_call.php";
          var data = new FormData();
          data.append('bin', '/apps/persephone/cgi/cart');
          data.append('stdin', 'new_cart(1)');
          axios.post(hostname, data, {
              headers: {
                'Content-Type': 'application/json'
              }
            })
            .then((response) => {
              this.products = response.data.data;
              if (response.data.data.status == "OK") {
                this.$cookies.set('persephone_cart', response.data.data.cart_id);
                console.log("Welcome to Persephone veShop! Your cart identification is " + this.$cookies.get(
                  "persephone_cart"));
              } else {
                console.log(response);

                Snackbar.open({
                  duration: 5000,
                  message: "Při tvorbě košíku se stala nehoda, prosím aktualizujte stránku. " + response.data
                    .message,
                  type: 'is-danger',
                  position: 'is-bottom-left',
                  actionText: 'Undo',
                  queue: false,
                });
              }
            })
            .catch(function (error) {
              Snackbar.open({
                duration: 5000,
                message: error,
                type: 'is-danger',
                position: 'is-bottom-left',
                actionText: 'Undo',
                queue: false,
              });
            });
        }
      }
    },
    metaInfo() {
      return {
        title: "Bedýnky ze dřeva",
        meta: [{
            name: 'description',
            content: 'Ručně vyráběné bedýnky ze dřeva, přírodní i stylově opálené.'
          },
          {
            property: 'og:title',
            content: "Bedýnky ze dřeva"
          },
          {
            property: 'og:site_name',
            content: 'Bedýnky ze dřeva'
          },
          {
            property: 'og:type',
            content: 'website'
          },
          {
            name: 'robots',
            content: 'index,follow'
          }
        ]
      }
    },
    meta: [{
        charset: 'utf-8'
      },
      {
        equiv: 'Content-Type',
        content: 'text/html'
      },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1'
      }
    ]
  }
</script>
