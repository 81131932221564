<template>
    <div class="vebd-footer">
   

        <div class="container">
            <div class="vebdf-level2">
                <div class="columns is-multiline is-variable is-6">
                    <div class="column is-hidden-mobile"></div>
                    <div class="column nobr is-narrow-tablet has-text-centered">
                        <img src="../assets/logo.png" class="vebdf-logo" alt="Bedýnky ze dřeva">
                        <div class="vebdf-maintext">
                            Bedýnky ze dřeva
                        </div>
                        <div class="vebdf-minitext nobr">
                            <i class="fas fa-phone"></i>&nbsp;Kontaktujte nás na čísle <span
                                class="has-text-black nobr">+420 774 369 497</span>
                        </div>
                    </div>
    <div class="column is-hidden-mobile"></div>

                    <div class="column  has-text-right is-hidden">
                        <p class="vebdf-title">Kontakt</p>
                        <b>
                            Martin Kebort</b>
                        <br>
                        Dílce 4<br>
                        506 01 Jičín<br>
                        <br>
                        <b>IČO: </b>76431690<br>info@bedynkyzedreva.cz


                    </div>
                    <div class="column  has-text-centered is-hidden-tablet">
                        <b>
                            Martin Kebort</b>
                        <br>
                        Dílce 4<br>
                        506 01 Jičín<br>
                        <br>
                        <b>IČO: </b>76431690<br>info@bedynkyzedreva.cz


                    </div>
                    <div class="column is-narrow is-hidden">
                        <p class="vebdf-title">Zde můžete platit</p>
                        <ul>
                            <li>
                                <a href="https://www.comgate.cz/cz/platebni-brana"><img
                                        src=" https://apatykabylinka.cz/assets/img/comgate.svg" class="pb-comgate"
                                        alt="Platební brána ComGate"></a>
                            </li>
                            <li>
                                <a href="https://www.mastercard.cz/cs-cz.html"><img
                                        src=" https://apatykabylinka.cz/assets/img/mastercard.svg" class="pb-mastercard"
                                        alt="Platební brána Mastercard"></a>
                            </li>
                            <li>
                                <a href="https://www.visa.cz/"><img src=" https://apatykabylinka.cz/assets/img/visa.svg"
                                        class="pb-visa" alt="Platební karta Visa"></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="vebdf-level3">
                <div class="columns is-centered-mobile is-mobile is-vcentered">
                    <div
                        class="column is-narrow has-text-black-transparent nonbr has-text-centered-mobile is-hidden-tablet-only is-hidden-desktop-only">
                        <p>© 2016-{{ new Date().getFullYear() }}
                            <b>Bedýnky ze dřeva.cz</b>
                        </p>
                    </div>
                    <div class="column is-hidden-tablet-only is-hidden-desktop-only is-hidden-mobile"></div>
                    <div class="column is-narrow is-hidden-mobile">
                        <a class="button is-ghost downlink" href="https://bedynkyzedreva.cz/document/gdpr.pdf">Ochrana
                            osobních
                            údajů</a>
                    </div>
                    <div class="column is-narrow">
                        <a class="button is-ghost downlink is-hidden-mobile"
                            href="https://bedynkyzedreva.cz/document/UniverzalniObchodniPodminky.pdf">Všeobecné
                            obchodní údaje</a>
                    </div>

                    <div class="column is-hidden-mobile">


                    </div>
                    <div class="column is-narrow is-hidden-mobile">
                        <a class="button is-large is-ghost downlink" href="https://www.facebook.com/bedynkyzedreva">
                            <span class="icon is-large">
                                <i class="fab fa-facebook"></i>
                            </span>
                        </a>
                    </div>
                    <div class="column is-narrow is-hidden-mobile">
                        <a class="button is-large is-ghost downlink" href="https://www.instagram.com/bedynkyzedreva">
                            <span class="icon is-large">
                                <i class="fab fa-instagram"></i>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="columns is-centered is-hidden-tablet is-mobile">
                    <div class="column is-narrow">
                        <a class="button is-large is-ghost downlink" href="https://www.facebook.com/bedynkyzedreva">
                            <span class="icon is-large">
                                <i class="fab fa-facebook "></i>
                            </span>
                        </a>
                    </div>
                    <div class="column is-narrow">
                        <a class="button is-large is-ghost downlink" href="https://www.instagram.com/bedynkyzedreva">
                            <span class="icon is-large">
                                <i class="fab fa-instagram "></i>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="has-text-centered"><small>Jste {{xty}}. návštěvník!</small></div>
        <br>
    </div>
</template>
<script>
import axios from "axios";
/*var instafeed = require("../instafeed");
var feed = instafeed.Instafeed({
    accessToken: 'a24c3f7edd7e8056c8c8665da87d9b71'
});
feed.run();*/

export default {
    name: 'veShopFooter',
    components: {
    },
    data() {
        return {
            infos: {
                name: "Bedýnky ze dřeva",
                established: "2016"
            },
            xty: 50
        }
    },
    async mounted()  {
            var data = new FormData();
            data.append('bin', '/apps/persephone/cgi/mainpage');
            data.append('stdin', 'get_visits(1)');
            const hostname = "https://bedynkyzedreva.cz/fujin3/api/rest/rest_call.php";
            axios.post(hostname, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => { 
                    //console.log(response);
                    this.xty = response.data.data.sumvisits
                });
       }
    

}
</script>
<style scoped>











.vebd-footer {
    width: 100%;
    height: 100%;
}
.hoverable {
    opacity: 80%;
    transition: all 0.5s ease;
}

.vebd-footer:hover .hoverable {
        opacity: 100%;
}


.pb-mastercard {
    height: 26px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.pb-comgate {
    height: 35px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.pb-visa {
    height: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.vebdf-logo {
    margin-bottom: 20px;
    height: 100px;
}

.vebdf-maintext {
    font-size: 2rem;
    color: black;
    font-family: 'Signika Negative',
        serif !important;
    font-weight: bold;
    line-height: 1.9rem;
    margin-bottom: 10px;
}

.vebdf-level2 {
    margin-top: 50px;
    margin-left: 25px;
    margin-right: 25px;
}
.vebdf-title {
    font-size: 1.2rem;
    color: #538023;

    font-family: 'Oswald',
        serif !important;
    font-weight: bold;
}
 .has-text-black-transparent
 {
    color: black;
    opacity: .8;
 }
.vebdf-minitext {
    color: #538023;
}
hr {
        background-color: #538023;
}
.vebdf-level3 {
    margin-top:25px;
    padding-left: 25px;
    padding-right: 20px;
    border-radius: 15px;
background-color:#c5dbb73d;
}


.downlink {
    color: #538023 !important;
    background-color: transparent !important;
    border: none;
}

.nonbr {
    black-space: nowrap;
}

</style>