<template>
  <div :class="[this.$parent.expanded ? 'expanded' : '','veshop-sidebar']">
      <div class="veshop-sidebar-logo is-hidden-tablet-only is-hidden-mobile-only">
          <router-link :to="{path:'/'}">
              <img src="../assets/logo.png">
          </router-link>
      </div>
      <div class="veshop-sidebar-spacer"></div>
      <div class="veshop-sidebar-menu">
            <b-button tag="router-link" to="/blog" type="is-primary is-light is-medium has-icon-custom" class="is-hidden">
                <img src="../assets/icons/blog.png">Blog
            </b-button>
            <b-button tag="router-link" to="/kategorie/boxy-a-truhly" type="is-medium has-icon-custom" class="is-hidden">
                <img src="../assets/icons/box.png">Boxy a truhly
            </b-button>
            <b-button tag="router-link" to="/kategorie/boxy-a-truhly/natural" type="is-medium is-light has-icon-custom">
                <img src="../assets/icons/box.png">Přírodní bedýnky
            </b-button><b-button tag="router-link" to="/kategorie/boxy-a-truhly/tanned" type="is-medium is-light has-icon-custom">
                <img src="../assets/icons/box-tan.png">Opálené bedýnky
            </b-button>
            <b-button tag="router-link" to="/kategorie/boxes" type="is-medium is-light has-icon-custom">
                <img src="../assets/icons/holder.png">Boxy
            </b-button>
            <b-button tag="router-link" to="/kategorie/cutouts" type="is-medium is-light has-icon-custom">
                <img src="../assets/icons/saw.png">Výřezy
            </b-button>
            <b-button tag="router-link" to="/kategorie/kose" type="is-medium has-icon-custom" class="is-hidden">
                <img src="../assets/icons/basket.png">Koše
            </b-button>
            <b-button tag="router-link" to="/kategorie/nosice-napoju" type="is-medium has-icon-custom" class="is-hidden">
                <img src="../assets/icons/holder.png">Nosiče nápojů
            </b-button>
            <b-button tag="router-link" to="/kategorie/regaly-na-vino" type="is-medium has-icon-custom" class="is-hidden">
                    <img src="../assets/icons/regal.png">Regály na víno
                </b-button>
      </div>
      <div class="veshop-sidebar-spacer"></div>
      <div class="veshop-sidebar-contact">
          <div class="vsc-1"><img src="../assets/icons/contact.png">Kontaktujte nás</div>
          <div class="vsc-2 has-text-primary">774 369 497</div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'veShopSidebar',
  props: {
  }
}
</script>