<template>
    <div>
        <div class="veshop-topbar">
            <div class="container is-fluid">
                <div class="columns is-mobile is-gapless">
                    <div class="column is-narrow is-hidden-desktop">
                        <b-button size="is-large" @click="updateExpanded()" icon-right="menu"
                            class="is-ghost topbar-button-tablet" />
                    </div>
                    <div class="column is-hidden-tablet"></div>
                    <div class="column is-narrow is-hidden-desktop">
                        <router-link to="/">
                            <img class="topbar-logo-tablet" src="../assets/logo.png">
                        </router-link>
                    </div>
                    <div class="column"></div>


                    <div class="column is-narrow is-hidden-mobile">
                        <div class="search-button">
                            <div class="columns">
                                <div class="column infocol-src">
                                    <input placeholder="Přenosný box na víno..." @input="doSearch" v-model="searched" class="sr1">
                                </div>
                                <div class="column fillcol-src">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-narrow">
                        <router-link tag="div" class="cart-button" to="/kosik">
                            <div class="columns">
                                <div class="column infocol-shc is-hidden-mobile" v-if="cart.hasItems">
                                    <p class="s1">Zboží v košíku</p>
                                    <p class="s2">{{cart.total}}</p>
                                </div>
                                <div class="column fillcol-shc">
                                    <b-field class="cart-counts" v-if="cart.items > 0">
                                        <b-tag rounded type="is-success">{{cart.items}}</b-tag>
                                    </b-field>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <transition name="fade">
            <div class="blanket" v-if="blanking" @click="searched = ''"></div>
        </transition>
        <transition name="fade">
            <div class="search-box" v-if="blanking">
                <div class="zobak"></div>
                <div class="no-results" v-if="search_results.number == 0 "></div>
                <div class="columns is-multiline" v-if="search_results.number != 0">
                    <div class="column is-one-quarter"  v-for="category in search_results.categories" :key="category.slug" @click="searched = ''" :style="category.delay">
                        
                                <router-link class="category-box" style="width:100%" tag="button" :to="category.slug">
                                    <img :src="category.icon" :alt="category.name">
                                    <p class="name">{{ category.name }}</p>

                                </router-link>
                            
                        
                    </div>
                    </div><div class="columns is-multiline">
                    <div class="column is-one-quarter is-full-mobile" v-for="product in search_results.products" :key="product.slug">
                <div class="product-box fade-in-bottom" :style="product.delay" >
                    <router-link tag="div" :to="product.link" class="product-image" :id="'image'+product.ID" :style="product.background">
                    </router-link>
                    <div class="pb-tags">
                        <b-field><b-tag type="is-warning is-light" v-if="product.freeShipping" icon="truck" icon-size="16">Doprava zdarma</b-tag></b-field>
                        <b-field><b-tag type="is-danger is-light" v-if="product.gift" icon="gift" size="16">Dárek!</b-tag></b-field>
                        <b-field><b-tag type="is-danger" v-if="product.sale > 0"><b>-{{product.sale}}% sleva</b></b-tag></b-field>
                    </div>
                    <div class="pb-counter">
                        <div class="count" v-if="product.inStock > 0">{{product.inStock}}</div>
                        <div class="count has-text-danger" v-if="product.inStock == 0">NENÍ</div>
                        <div class="count" v-if="product.inStock == '*'">JE</div>
                        <div class="countTag">SKLADEM</div>
                    </div>

                    
                    <div class="pb-name-buttons">
                        <router-link tag="p" :to="product.link">{{product.name}}</router-link>
                        <div class="buttons-custom">
                        <b-button size="is-meidu"  type="is-primary is-light" icon-left="cart" :data-product="product.ID" :id="'button'+product.ID"  @click="addToCart(product.ID,0)"> 
                           <b>{{product.price}} {{product.currency}}</b>
                        </b-button>
                        <b-button size="is-meidu" class="is-hidden" type="is-light" icon-right="heart"/>

                        </div>
                    </div>
                </div>
            </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import axios from "axios";
    import debounce from 'lodash/debounce';

    export default {
        name: 'veShopTopbar',
        props: ['cart'],
        data() {
            return {
                searched: "",
                search_results: {
                    categories: [],
                    products: [],
                    number: 0
                }
            }
        },
        computed: {
            blanking: function () {
                if (this.searched != "")
                    return true;
                else
                    return false;
            }
        },
        methods: {
            updateExpanded: function () {
                this.$parent.expanded = !this.$parent.expanded
            },
            addToCart(productId, gifts) {
                document.getElementById("button" + productId).classList.add("is-loading");
                this.$parent.addToCart(productId, 1, 0, gifts);

                setTimeout(function () {
                    document.getElementById("button" + productId).classList.remove("is-loading");
                }, 800);
            },
            doSearch: debounce(function () { 
                var data = new FormData();
                this.$gtag.event("search", {
                'event_category': "engangement",
                'event_label': "search_term",
                'value': this.searched
            })
                data.append('bin', '/apps/persephone/cgi/search');
                data.append('stdin', 'search("'+this.searched+'")');
                const hostname = "https://bedynkyzedreva.cz/fujin3/api/rest/rest_call.php";
                axios.post(hostname, data, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then((response) => {
                        this.search_results = response.data.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }, 500)
        },
    }
</script>