<template>
    <div>
        <div class="filters">
            <div class="columns is-mobile is-multiline">
                <div class="column is-narrow">
                    <b-field>
                        <b-checkbox @input="filterLoadProduct" v-model="filter.stock">Skladem</b-checkbox>
                    </b-field>
                    
                </div>
                <div class="column  is-full-mobile">
                    <b-field label="Cena">
                        <div class="columns is-mobile is-vcentered">
                            <div class="column is-narrow">Od {{filter.minprice}} Kč</div>
                            <div class="column">
                                <b-slider @change="filterLoadProduct" v-model="filter.numbers" :min="filter.minprice"
                                    :max="filter.maxprice" :step="1">
                                </b-slider>
                            </div>
                            <div class="column is-narrow">Do {{filter.maxprice}} Kč</div>
                        </div>

                    </b-field>
                </div>
                <div class="column is-narrow is-half-mobile">
                    <b-field has-addons label="Podle názvu">
                        <p class="control">
                            <b-button :type="(filter.name=='down') ? 'is-primary is-light' : 'is-light'"
                                @click="(filter.name=='down') ? filter.name=null : filter.name='down';filterLoadProduct()">
                                <i class="fas fa-sort-alpha-down"></i></b-button>
                        </p>
                        <p class="control">
                            <b-button :type="(filter.name=='up') ? 'is-primary is-light' : 'is-light'"
                                @click="(filter.name=='up') ? filter.name=null : filter.name='up';filterLoadProduct()">
                                <i class="fas fa-sort-alpha-up"></i></b-button>
                        </p>
                    </b-field>
                </div>
                <div class="column is-narrow is-half-mobile">
                    <b-field has-addons label="Podle ceny">
                        <p class="control">
                            <b-button :type="(filter.price=='down') ? 'is-primary is-light' : 'is-light'"
                                @click="(filter.price=='down') ? filter.price=null : filter.price='down';filterLoadProduct()">
                                <i class="fas fa-sort-numeric-down"></i></b-button>
                        </p>
                        <p class="control">
                            <b-button :type="(filter.price=='up') ? 'is-primary is-light' : 'is-light'"
                                @click="(filter.price=='up') ? filter.price=null : filter.price='up';filterLoadProduct()">
                                <i class="fas fa-sort-numeric-up"></i></b-button>
                        </p>
                    </b-field>
                </div>
            </div>
        </div>
        <div class="columns is-multiline is-mobile" id="prods">
            <div class="column is-full not-found" v-if="products.length == 0">

            </div>
            <div class="column is-one-quarter-widescreen is-one-third-desktop is-one-third-tablet is-half-mobile"
                v-for="product in products" :key="product.slug">
                <div class="product-box fade-in-bottom" :style="product.delay">
                    <router-link tag="div" :to="product.link" class="product-image" :id="'image'+product.ID"
                        :style="product.background">
                    </router-link>
                    <div class="pb-tags">
                        <b-field>
                            <b-tag type="is-warning is-light" v-if="product.freeShipping" icon="truck" icon-size="16">
                                Doprava zdarma</b-tag>
                        </b-field>
                        <b-field>
                            <b-tag type="is-danger is-light" v-if="product.gift" icon="gift" size="16">Dárek!</b-tag>
                        </b-field>
                        <b-field>
                            <b-tag type="is-danger" v-if="product.sale > 0"><b>-{{product.sale}}% sleva</b></b-tag>
                        </b-field>
                    </div>
                    <div class="pb-counter">
                        <div class="count" v-if="product.inStock > 0">{{product.inStock}}</div>
                        <div class="count has-text-danger" v-if="product.inStock == 0">NENÍ</div>
                        <div class="count" v-if="product.inStock == '*'">JE</div>
                        <div class="countTag">SKLADEM</div>
                    </div>


                    <div class="pb-name-buttons">
                        <router-link tag="p" :to="product.link">{{product.name}}</router-link>
                        <div class="buttons-custom">
                            <b-button size="is-meidu" :type='{"is-primary": product.sale <= 0, "is-danger": product.sale > 0, "is-light":true}' icon-left="cart"
                                :data-product="product.ID" :id="'button'+product.ID" @click="addToCart(product.ID,0)">
                                <b>{{product.price_notax}} {{product.currency}}</b>
                            </b-button>
                            <b-button size="is-meidu" class="is-hidden" type="is-light" icon-right="heart" />

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-pagination :total="total" v-model="current" order="is-centered" :range-after="5" :range-before="5"
            :per-page="totalPage" aria-next-label="Další strana" aria-previous-label="Předchozí strana"
            aria-page-label="Strana" aria-current-label="Tato strana" @change="filterLoadProduct">
        </b-pagination>
        <br>
    </div>
</template>

<script>
    import axios from "axios";
    import {
        SnackbarProgrammatic as Snackbar
    } from 'buefy';
    export default {
        name: 'veShopProductsComponent',
        props: {
            categoryId: String,
            subcategoriesIds: Array
        },
        data() {
            return {
                products: [

                ],
                original: [

                ],

                total: 0,
                totalPage: 0,
                current: 1,
                filter: {
                    price: null,
                    name: null,
                    stock: true,
                    gift: false,
                    freeship: false,
                    numbers: [0, 0],
                    minprice: 0,
                    maxprice: 0
                }
            };
        },
        methods: {
            addToCart(productId, gifts) {
                document.getElementById("button" + productId).classList.add("is-loading");
                this.$parent.$parent.addToCart(productId, 1, 0, gifts);

                setTimeout(function () {
                    document.getElementById("button" + productId).classList.remove("is-loading");
                }, 800);
            },
            async loadProduct() {
                if (window.innerWidth < 960) {
                    document.getElementById("main").scrollTo(0, 0);
                }
                let category = this.$props.categoryId;
                if (this.$props.subcategoriesIds.length > 0) {
                    category = this.$props.subcategoriesIds.join(";");
                }
                const hostname = "https://bedynkyzedreva.cz/fujin3/api/rest/rest_call.php";
                var data = new FormData();
                data.append('bin', '/apps/persephone/cgi/products');
                data.append('stdin', 'get_products(' + category + ',' + this.current + ',' + this.filter.name +
                    ',' + this.filter.price + ',' + this.filter.stock +
                    ',' + this.filter.gift + ',' + this.filter.freeship + ',' + this.filter.numbers[1] + ',' +
                    this.filter.numbers[0] + ')');
                axios.post(hostname, data, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then((response) => {
                        console.log(this.current);
                        if(this.current == 1)
                        {
                        this.total = response.data.data.maxprods;
                        this.totalPage = response.data.data.pagination;
                        }
                        this.products = JSON.parse(JSON.stringify(response.data.data.products));
                        this.filter.minprice = Number(response.data.data.minprice);
                        this.filter.maxprice = Number(response.data.data.maxprice);
                        this.filter.numbers = [this.filter.minprice, this.filter.maxprice];
                    })
                    .catch(function (error) {
                        Snackbar.open({
                            duration: 5000,
                            message: error,
                            type: 'is-danger',
                            position: 'is-bottom-left',
                            actionText: 'Undo',
                            queue: false,
                        });
                    });
            },
            async filterLoadProduct() {
                if (window.innerWidth < 960) {
                    document.getElementById("main").scrollTo(0, 0);
                }
                let category = this.$props.categoryId;
                if (this.$props.subcategoriesIds.length > 0) {
                    category = this.$props.subcategoriesIds.join(";");
                }
                const hostname = "https://bedynkyzedreva.cz/fujin3/api/rest/rest_call.php";
                var data = new FormData();
                data.append('bin', '/apps/persephone/cgi/products');
                data.append('stdin', 'get_products(' + category + ',' + this.current + ',' + this.filter.name +
                    ',' + this.filter.price + ',' + this.filter.stock +
                    ',' + this.filter.gift + ',' + this.filter.freeship + ',' + this.filter.numbers[1] + ',' +
                    this.filter.numbers[0] + ')');
                axios.post(hostname, data, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then((response) => {
                        if(this.current == 1)
                        {
                        this.total = response.data.data.maxprods;
                        this.totalPage = response.data.data.pagination;
                        }
                        this.products = JSON.parse(JSON.stringify(response.data.data.products));
                    })
                    .catch(function (error) {
                        Snackbar.open({
                            duration: 5000,
                            message: error,
                            type: 'is-danger',
                            position: 'is-bottom-left',
                            actionText: 'Undo',
                            queue: false,
                        });
                    });
            },
        },
        mounted() {
            this.loadProduct();
        }
    }
</script>